
import React, { useState, useEffect } from "react";
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import rup from '../images/global-rupeeIcon.png'
import qrcode from '../images/qrcode.jpg';
import { Spinner } from "react-bootstrap";
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Deposit_Amount = () => {
  const [loading, setLoading] = useState(false);
  const deposit_amount = localStorage.getItem("deposit_amount");



  // Api Withdraw Amount
  const [utr, setutr] = useState('')

  
  const handleAddBankSubmit = async () => {

    setLoading(false); // Reset loading state
    if (utr.length < 12) {
      toast.error("UTR should be at least 12 characters long"); // Show an alert message
      return;
    }
    setLoading(true);

    const userId = localStorage.getItem("userid");
    const deposit_amount = localStorage.getItem("deposit_amount");

    let url = (`https://mrludoclub.com/Admin/public/api/deposit-request`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('user_id', userId);
    formData.append('amount', deposit_amount);
    formData.append('utr', utr);

    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        if (response.data.status == 'success') {
          console.warn(response.data.success)
          const MySwal = withReactContent(Swal)
          MySwal.fire({
            title: response.data.message,
            icon: 'success',
            timer: 2000
          })
          .then((result) => {
              setutr(' ')
              window.location.href = '/';
            })
          // toast.success(response.data.message, {
          //   onClose: () => {
          //     // Reload the window after the toast message is closed
          //     window.location.reload();
          //     setShowSubmitButton(false);
          //   }
          // });
        } else {
          const MySwal = withReactContent(Swal)
          MySwal.fire({
            title: response.data.message,
            timer: 2500,
            icon: 'error'
          })
        }
        // const objectRes = JSON.parse(res);
        // setUsers(objectRes);
        
        // setIsLoading(false);
        // console.warn(objectRes);

      })
      .finally(() => {
        // setTimeout(() =>{
        setLoading(false);
    // },5000);
    });

  }

  
 
 
  return (
    <>
      <div className="leftContainer">
        <div>
     
          <div className="divider-x" />

          <div className="container">
            <div className="d-flex justify-content-center">
               <div className="image_qr">
                 <img src={qrcode} alt="qrcode" />
               </div>
            </div>
            <div className="main-area" style={{ paddingTop: '0px' }}>
              <h5 className="my-3 mb-4" style={{ color: '#2c2c2c', fontSize: '1em', fontWeight: 700 }}>Deposit Amount {deposit_amount}</h5>
              <div id="sign_up_success" />
              {/* <form method="post"> */}
              <input type="hidden" name="_token" defaultValue="jsoO9cwynnsTkYIjpQAuUf4RJTpZ3SFHCnrDOed7" /> <label className="w-40 mb-0" style={{ color: '#0000008a' }}>UTR NUMBER</label>
              <input type="number" name="amount" value={utr}  onChange={(e) => setutr(e.target.value.slice(0, 12))} id="amount" min={0}  className="form-control border-bottom d-inline mb-2" style={{ border: 'none' }}  />
              <div className="text-danger" style={{ marginLeft: '20%' }} />
              <br />
             
              <div className="row justify-content-center">
                <button disabled={loading || !utr} type="primary" onClick={handleAddBankSubmit} className="btn btn-primary my-2 w-100 mx-3 refer-button" id="redeemNow" >Submit
                {loading && <Spinner animation="border" className="ml-2"  style={{width:'20px', height:'20px'}}/>}
                </button>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );

};
export default Deposit_Amount;