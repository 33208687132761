import React from "react";
import { Outlet, Link } from "react-router-dom";
const Rules = () => {
  return (
    <>
      <div className="container"></div>
      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: '60px' }}>
          <div className="m-3">
            <h2>mrludoclub Classic Ludo Rules</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">mrludoclub Classic Ludo Rules</li>
              </ol>
            </nav>
            <div className="row">
              <div className="col-12">
                <h4>Game Rules :-</h4>
                {/* <ol className="rules-list">
                  <li>On Winning both players have to update their result in following manner.</li>
                  <ul>
                    <ul style={{ listStyleType: 'circle' }}>
                      <li>If you have won the balttle, select 'I won' option and upload winning
                        screenshot of the game.</li>
                      <li>If you have lost the battle, select 'I lost' option.</li>
                      <li>If your battle is not started and your opponent dosen't want to play, select
                        'cancel' option.</li>
                    </ul>
                  </ul>
                  <li>A player must have to record every game, and if any player is hacking or cheating in
                    a game, please contact support with video proof.</li>
                  <li>If your game is not started, and if you haven't played a single move yourself,
                    please show us a recording of the game as proof. The game will be canceled only if
                    you have recorded.</li>
                  <li>If you don't have any proof against player cheating and error in the game, then you
                    will be considered as lost for a particular battle.</li>
                  <li>If you haven't moved a single pawn or no pawn is open yet,i.e. all pawns are at
                    home, then your game will be cancelled.</li>
                  <li>If your opponent leaves match purposely in starting or initial game, and the
                    opponent dosen't have any valid proof of cancellation, you will be awarded a 50%
                    win.</li>
                </ol>
                <h4>Commission Rates :-</h4>
                <ol>
                  <li>Battle below 250₹, 5% <b>commission</b> will be charged on battle amount.</li>
                  <li>Battle between 250₹ to 500₹, <b>flat 25₹</b> commission will be charged.</li>
                  <li>Battle above 500₹, <b>commission</b> will be charged on battle amount.</li>
                </ol> */}
                1. यदि आप जान भुजकर Autoexit करते है तो भी आपको 100% Loss कर दिया जायेगा ! यदि दोनों प्लेयर में किसी की काटी खुली नहीं तो उसे हम कैंसिल कर सकते है !<br></br>
                2. यदि एक टोकन बाहर है और घर के पास है तो 30% Loss दिया जायेगा लेकिन यदि गेम खेला गया है और 2 काटी बहार आयी हो तो गेम को लेफ्ट करने वाले को 100% Loss कर दिया जायेगा !
                3. Autoexit में यदि 1 टोकन बाहर है तो गेम कैंसिल किया जा सकता है लेकिन यदि आपने गेम जान भुजकर में छोड़ा होगा तो आपको Loss ही दिया जायेगा इसमें अंतिम निर्णय Admin का होगा !
                4. यदि आपको लगता है की Opponent ने जानभूझकर गेम को Autoexit में छोड़ा है लेकिन Admin ने कैंसिल कर दिया है तो आपसे वीडियो प्रूफ माँगा जायेगा इसलिए हर गेम को रिकॉर्ड करना जरुरी है ! यदि आप वीडियो प्रूफ नहीं देते है तो गेम रिजल्ट एडमिन के अनुसार ही अपडेट किया जायेगा चाहे आप विन हो या गेम कैंसिल हो !
                5. Game समाप्त होने के 15 मिनट के अंदर रिजल्ट डालना आवश्यक है अन्यथा Opponent के रिजल्ट के आधार पर गेम अपडेट कर दिया जायेगा चाहे आप जीते या हारे और इसमें पूरी ज़िम्मेदारी आपकी होगी इसमें बाद में कोई बदलाव नहीं किया जा सकता है !
                6. Win होने के बाद आप गलत स्क्रीनशॉट डालते है तो गेम को सीधा Cancel कर दिया जायेगा इसलिए यदि आप स्क्रीनशॉट लेना भूल गए है तो पहले Live Chat में एडमिन को संपर्क करे उसके बाद ही उनके बताये अनुसार रिजल्ट पोस्ट करे !
                7. दोनों प्लेयर की टोकन (काटी) घर से बाहर न आयी हो तो लेफ्ट होकर गेम कैंसिल किया जा सकता है ! [कैंसिल प्रूफ करने के लिए वीडियो आवश्यक होगा]
                8. 'कैंसिल' रिजल्ट डालने के बाद गेम प्ले करके जीत जाते है तो उसमे हमारी कोई ज़िम्मेदारी नहीं होगी अतः गेम कैंसिल करने के बाद स्टार्ट न करे अन्यथा वो कैंसिल ही माना जायेगा
                9. एक बार रिजल्ट डालने के बाद बदला नहीं जा सकता है इसलिए सोच समझकर रिजल्ट पोस्ट करे गलत रिजल्ट डालने पर पेनल्टी भी लगायी जाएगी चाहे आपने वो गलती से डाला हो या जान भुजकर
                10.Fresh आईडी गेम के मामले में केवल पासा के उपयोग के प्रमाण पर रद्द कर दिया जाएगा गोटी के खुले होने के बावजूद नहीं ? गेम का रिकॉड होना जरूरी है अगर कोई अपनी आईडी का नाम नो फ्रेश कर रका तो नो फ्रेश आईडी ही टेक करे जिनका इंटरेस्ट हो वही टेक करे टेबल को ( इसका अंतिम रिजल्ट एडमिन देगा )
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default Rules;