
import React from "react";
import { Outlet, Link } from "react-router-dom";
const Contactus = () => {
  return (
    <>

      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: '60px' }} >
          <div className="m-3">
            <h2>Contact Us</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/vplay-bet">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
              </ol>
            </nav>
            <div className="row">
              <div className="col-12">
                <h4>Contact Us:</h4>
                <ol className="rules-list">
                  For any kind of queries, please contact us on the below mention details<br /><br />
                  <h5>Contact</h5>
                  <ul>
                    <li><a href="www.Vplay.com">www.mrludoclub.com</a></li>
                    <li><a href="infi@mrludoclub.com">info@mrludoclub.com</a></li>
                  </ul>
                  <h5>Operational Address:</h5>
                  Demo
                  <h5>To submit your game write to us:</h5>
                  <a href="infi@mrludoclub.com">info@mrludoclub.com</a>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contactus;