import React, { useEffect, useState } from "react";
import rup from '../images/global-rupeeIcon.png'
import axios from "axios";
import profile7 from '../images/order-history.png'

import { Outlet, Link } from "react-router-dom";

const Addmoney = () => {

  const [users, setUsers] = useState([]);
  useEffect(
    () => {
      loaduser();
    }, []
  );

  const loaduser = async () => {
    const token = localStorage.getItem("token_store_vplay");

    var data = '';

    var config = {
      method: 'post',
      url: 'https://api.mrludoclub.com/api/users/get-wining-cash',
      headers: {
        'x-access-token': token
      },
      data: data,
      setdata: () => {
        sessionStorage.setItem(token)
      }
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        // console.log(objectRes.data);
        // alert(objectRes.data)
        setUsers(objectRes);
        console.log(objectRes.data);
        config.setdata();
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  // const [users, setUsers] = useState([]);
  // useEffect(
  //     ()=>{
  //         loaduser();
  //     },[]
  // );
  // const loaduser = async () =>{
  // const result = await axios.get("http://103.129.97.243:8080/api/users/get-wining-cash");
  // headers: {
  //   'x-access-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NzksImlhdCI6MTY3NDU1OTcyMiwiZXhwIjoxNzA2MDk1NzIyfQ.gib6wwBB1LNT2uhmmAPksnPW4OFwXFyNU83NJgYM5wk'
  //      },
  // console.log(result,"abc");
  // setUsers(result.data);

  //   const res = await axios.get("http://103.129.97.243:8080/api/users/get-wining-cash", {
  //     headers: {
  //       'x-access-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NzksImlhdCI6MTY3NDU1OTcyMiwiZXhwIjoxNzA2MDk1NzIyfQ.gib6wwBB1LNT2uhmmAPksnPW4OFwXFyNU83NJgYM5wk'
  //     } 
  //  })
  //  console.log(res);

  //   await axios.get('http://103.129.97.243:8080/api/users/get-wining-cash',
  //   {
  //     headers: {
  //             'x-access-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NzksImlhdCI6MTY3NDU1OTcyMiwiZXhwIjoxNzA2MDk1NzIyfQ.gib6wwBB1LNT2uhmmAPksnPW4OFwXFyNU83NJgYM5wk'
  //           } 

  //   })
  // .then(res => {
  //   console.log(res)
  // })
  // .catch(e => console.log(e))


  // }


  return (
    <>

      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: '60px' }}>
          <div className="p-4 bg-light"><Link className="d-flex align-items-center profile-wallet undefined" to="/transaction-history">
            <picture className="ml-4"><img width="32px" src={profile7} alt="" /></picture>
            <div className="ml-5 mytext text-muted ">Order History</div>
          </Link></div>
          <div className="divider-x" />
          <div className="p-4 bg-light">
            <div className="wallet-card" style={{ backgroundColor: 'rgb(71, 130, 244)', backgroundImage: 'url("/images/bg-wallets-deposit.png")' }}>
              <div className="d-flex align-items-center">
                <picture className="mr-1"><img height="26px" width="26px" src={rup} alt="" /></picture><span className="text-white" style={{ fontSize: '1.3em', fontWeight: 900 }}>₹{users.deposit_cash}</span>
              </div>
              <div className="text-white text-uppercase" style={{ fontSize: '0.9em', fontWeight: 500 }}>Deposit Cash
              </div>
              <div className="mt-5" style={{ fontSize: '0.9em', color: 'rgb(191, 211, 255)' }}>Can be used to play
                Tournaments &amp; Battles.<br />Cannot be withdrawn to Paytm or Bank.</div><a href="/add-fund" className="walletCard-btn d-flex justify-content-center align-items-center text-uppercase">Add
                  Cash</a>
            </div>
            <div className="wallet-card" style={{ backgroundColor: 'rgb(127, 153, 255)', backgroundImage: 'url("/images/bg-wallets-winnings.png")' }}>
              <div className="d-flex align-items-center">
                <picture className="mr-1"><img height="26px" width="26px" src={rup} alt="" /></picture><span className="text-white" style={{ fontSize: '1.3em', fontWeight: 900 }}>₹{users.data}</span>
              </div>
              <div className="text-white text-uppercase" style={{ fontSize: '0.9em', fontWeight: 500 }}>Winnings Cash
              </div>
              <div className="mt-5" style={{ fontSize: '0.9em', color: 'rgb(216, 224, 255)' }}>Can be withdrawn to Paytm
                or Bank. Can be used to play Tournaments &amp; Battles.</div><Link to="/withdraw-amount" className="walletCard-btn d-flex justify-content-center align-items-center text-uppercase">Withdraw</Link>
            </div>
            {/* <div className="wallet-card" style={{ backgroundColor: 'rgb(71, 130, 244)', backgroundImage: 'url("/images/bg-wallets-deposit.png")' }}>
              <div className="d-flex align-items-center">
                <picture className="mr-1"><img height="26px" width="26px" src={rup} alt="" /></picture><span className="text-white" style={{ fontSize: '1.3em', fontWeight: 900 }}>₹{users.deposit_cash}</span>
              </div>
              <div class="text-white text-uppercase" style="font-size: 0.9em; font-weight: 500;">Deposit Cash</div>
              <div className="text-white text-uppercase" style={{ fontSize: '0.9em', fontWeight: 500 }}>Send To Friends</div>
              <div className="mt-5" style={{ fontSize: '0.9em', color: 'rgb(191, 211, 255)' }}>Send Money Your Friends
                Wallet </div>
              <a href="/send-money" className="walletCard-btn d-flex justify-content-center align-items-center text-uppercase">Send
                Money</a>
            </div> */}
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default Addmoney;