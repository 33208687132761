import React from "react";
const Termcondition = () => {
  return (
    <>
      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: '60px' }}>
          <div className="m-3">
            <h2>Terms &amp; Condition</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Terms &amp; Condition</li>
              </ol>
            </nav>
            <div className="row">
              <div className="col-12">
                <ol>
                  <h4>
                    <li>The Terms </li>
                  </h4>
                  <ol>
                    <li>
                      These Agreements is a lawfully restricting report and is an electronic record as
                      an
                      electronic agreement framed under Data Innovation Act, 2000 and rules made
                      thereunder. These
                      Agreements should be perused related to the Terms of Purpose of the Application
                      (hereinafter
                      alluded to as "Terms of Purpose") and the Protection Strategy of the Application
                      (hereinafter alluded to as "Security Policy").These Terms of Administration (the
                      "Terms")
                      are a lawful and authoritative understanding between clients ("Clients") and
                      Gazick Private
                      Restricted (alluded to as the "Mrludoclub"), corresponding to all games and
                      applications made
                      accessible by Mrludoclub (mutually and reciprocally alluded to as the
                      "Administrations"), and any
                      data, message, designs, video, sound, pictures, and some other materials showing
                      up, sent,
                      transferred, conveyed, communicated or generally made accessible through the
                      previously
                      mentioned Administrations (together alluded to as the "Content").
                    </li>
                    <li>By getting to as well as utilizing the Administrations, Clients consent to be
                      limited by
                      these Terms and Mrludoclub Protection Strategy, as expressed on passage 5.</li>
                    <li>Clients express that they are of lawful age (least 18 years old) to get to the
                      Administrations and Content, or on the other hand if legitimately conceivable,
                      to access
                      with their legitimate watchman assent, due approval and concurrence with these
                      Terms.</li>
                    <li>Impo Notification:</li>
                    <ul type="disc">
                      <li>THE Challenge FOR STAKES IS OPEN Just TO INDIAN Residents, Dwelling IN INDIA
                        With the
                        exception of THE Occupants OF THE INDIAN Provinces OF ASSAM, ODISHA,
                        NAGALAND, SIKKIM,
                        MEGHALAYA, ANDHRA PRADESH, AND TELANGANA.</li>
                      <li>Mrludoclub Doesn't OFFER POKER FOR STAKES Inside THE Domain OF GUJARAT. THE
                        Inhabitants OF
                        GUJARAT Anyway ARE NOT Confined FROM PLAYING THE Allowed TO PLAY Arrangement
                        OF POKER,
                        Presented BY Mrludoclub.</li>
                      <li>Mrludoclub Doesn't OFFER RUMMY FOR STAKES Inside THE Domain OF KERALA. THE
                        Inhabitants OF
                        KERALA Anyway ARE NOT Limited FROM PLAYING THE Allowed TO PLAY Arrangement
                        OF RUMMY,
                        Presented BY Mrludoclub.</li>
                      <li>Residents Or potentially Occupants OF Nations OTHER THAN INDIA ARE NOT
                        Qualified TO
                        Partake IN THE Challenge.</li>
                      <li>Easygoing GAMING IS Permitted ACROSS ALL Expresses; THE GAME IS Allowed TO
                        BE PLAYED FOR
                        STAKES IN THE STATES WHICH Don't Limit, Disallow Something similar, AS
                        Featured IN THESE
                        TERMS.</li>
                      <li>On the off chance that YOU ARE Living Or potentially Getting to THE
                        Application FROM ANY
                        District/STATE/Nation WHERE THE Challenge FOR STAKES IS Denied OR Limited BY
                        Regulation
                        OR Different REASONS, THEN YOU ARE Disallowed FROM Enrolling AND Taking part
                        IN THE SAID
                        Challenge. YOU ARE Liable FOR Consistence OF ANY Regulations THAT ARE
                        Relevant ON YOUR
                        Nation/Habitation/STATE/Home. On the off chance that YOU Partake IN THE
                        Challenge BY
                        Distortion, THEN Mrludoclub Will IN ITS Only Tact Reserve THE Privilege TO
                        Exclude YOU AT
                        ANY Phase OF THE Cycle, Relinquish ANY Award (AS Characterized Underneath)
                        AND Make A
                        legitimate Move AGAINST YOU.</li>
                      <li>On the off chance that YOU ARE Viewed OR Thought As Cheating THE Frameworks
                        OF THE
                        Challenge IN ANY Way THEN YOU Will BE Suspended FROM Partaking IN THE
                        Challenge AND
                        Mrludoclub Might Make A legitimate Move AGAINST YOU.</li>
                      <li>Workers OF Mrludoclub, ITS Particular HOLDING, Auxiliaries, Partner Organizations
                        AND
                        Outsider Specialist co-ops WHO HAVE BEEN Locked in BY Mrludoclub FOR THE Turn of
                        events,
                        Advancement, Organization OR EXECUTION OF THE Challenge, AND THEIR
                        FAMILY/Family
                        Individuals ARE NOT Qualified TO Take part IN THE Challenge.</li>
                      <li>In the event of ANY Question In regards to THE Application OR THE Challenge,
                        Mrludoclub'S
                        Choice Will Last AND Tie.</li>
                      <li>Mrludoclub Maintains whatever authority is needed TO CHANGE OR Alter THIS
                        Agreements Now
                        and again, Tentatively OR Reflectively, AT ITS Only Caution AND With
                        practically no
                        Earlier Insinuation TO YOU. YOU ARE Mentioned TO Peruse THESE Agreements
                        Every once in a
                        while Prior to Involving THE Application OR Partaking IN Challenge
                        cautiously. IT Will
                        BE YOUR Obligation TO Really look at THESE Agreements Intermittently FOR
                        CHANGES.
                        Mrludoclub Might Expect YOU TO Give YOUR Immediate OR Roundabout Agree TO ANY
                        Refresh IN A
                        Predetermined Way BEFORE Additional Utilization OF Application OR Support IN
                        THE
                        Challenge. Assuming that NO SUCH Discrete Assent IS Looked for, YOUR
                        Proceeded with
                        Utilization OF Application OR Support IN THE Challenge, FOLLOWING SUCH
                        CHANGES, WILL
                        Comprise YOUR Acknowledgment OF THOSE CHANGES.</li>
                      <li>Mrludoclub Maintains whatever authority is needed TO Pull out OR Stop OR End THE
                        Challenge
                        AT ANY STAGE WITHOUT Earlier Notification AND With no Responsibility At all
                        TO YOU.</li>
                      <li>THE Challenge IS VOID WHERE Denied BY Regulation.</li>
                      <li>IN ANY Challenge, INCASE OF A DRAW/TIE, A Ultimate conclusion WOULD BE OF
                        THE
                        Organization. THERE WOULD BE NO Discounts IN ANY Circumstance.</li>
                    </ul>
                  </ol>
                  <h4>
                    <li> The Services </li>
                  </h4>
                  <ol>
                    <li> 2.1. Mrludoclub gives Clients in consistence with these Terms an individual,
                      sovereignty free, non-assignable, non-select and revocable restricted permit to
                      utilize the product that is given as a feature of the Administrations. This
                      permit is for the sole motivation behind empowering the own confidential use
                      from Clients to partake in the Administrations as given by Mrludoclub, in the way
                      concurring with by these Terms. The Administrations might change or being
                      adjusted every once in a while without earlier notification or correspondence.
                      Moreover Mrludoclub may, at its own circumspection, stop absolutely or to some
                      degree, as well as forever or briefly the arrangement of the Administrations or
                      Clients accounts without earlier notification or correspondence.</li>
                    <li>2.2. The Administrations might incorporate notices, which might be designated to
                      the Substance or data on the Administrations, inquiries made through the
                      Administrations, or some other data. The sorts and degree of promoting by Mrludoclub
                      on the Administrations are dependent upon future developments. In thought for
                      Mrludoclub allowing you admittance to and utilization of the Administrations, you
                      concur that Mrludoclub and its outsider suppliers and accomplices might put such
                      promoting on the Administrations or regarding the presentation of Content or
                      data from the Administrations whether put together by you or others.</li>
                    <li>2.3. At the point when access games remembered for the Administrations, the
                      particular standards, scoring, controls and rules for each game can be tracked
                      down inside the actual game. Such principles are essential piece of the Terms,
                      which Clients will concur and go along.</li>
                    <li>2.4. Any charges connected with Clients for getting to the Administrations,
                      including yet not restricted to web association and additionally portable or
                      information charges are full liability of such Clients.</li>
                  </ol>
                  <h4>
                    <li> Content </li>
                  </h4>
                  <ol>
                    <li> 3.1. Dependent upon your consistence with these Terms, Mrludoclub awards you a
                      restricted, non-selective, non-adaptable, non-sublicensable permit to access and
                      view the Substance exclusively regarding your allowed utilization of the
                      Administrations and exclusively for your own and non-business purposes.</li><br />
                    The Substance accessible through the Administrations has not been checked or
                    verified by us, and may incorporate mistakes or bogus data. We make no portrayals,
                    guarantees, or ensures regarding our Administrations or any Satisfied on the
                    Administrations, connecting with the quality, reasonableness, truth, exactness or
                    fulfillment of any happy contained in the Administrations. You recognize sole
                    liability regarding and expect all chance emerging from your utilization or
                    dependence of any Satisfied..
                  </ol>
                  <h4>
                    <li> Users accounts </li>
                  </h4>
                  <ol>
                    <li> 4.1. Clients are liable for defending the secret word that utilization to get
                      to the Administrations. Mrludoclub urge Clients to utilize solid passwords (passwords
                      that utilization a mix of upper and lower case letters, numbers and images) with
                      the records. Mrludoclub can't and won't be obligated for any misfortune or harm
                      emerging from Clients inability to consent to the abovementioned.</li>
                    <li> 4.2. Clients consent to make all strides important to safeguard sign in
                      subtleties and stay discreet, and not give login subtleties to any other person
                      or permit any other individual to utilize their login subtleties or record,
                      remembering log for subtleties and record for any informal organization or stage
                      that Clients might permit the Administrations to cooperate with. Mrludoclub will not
                      have any liability regarding the outcomes of disappointment by Clients to these
                      arrangements, and consent to completely remunerate Mrludoclub for any misfortunes or
                      damage that might result. Moreover, Mrludoclub will not be liable for any misfortune
                      that Clients might endure because of an unapproved admittance to their records
                      as well as utilization of the Administrations, and Mrludoclub acknowledges no
                      liability regarding any misfortunes or mischief coming about because of this
                      unapproved use, whether falsely etc.</li>
                  </ol>
                  <h4>
                    <li> Contests, Deals, Levels and In App purchases</li>
                  </h4>
                  <ol>
                    <li /> Mrludoclub might lead advancements, including, without impediment, challenges,
                    incidental awards, Level reclamations. Mrludoclub isn't engaged with a way with the
                    challenge. Every advancement might have extra Terms and rules, which will be posted
                    or generally made accessible to you, and for reasons for every Advancement, will be
                    considered integrated into and structure a piece of this understanding.
                    <li>5.1. Estimating of, Buying and Accessibility of Levels. Mrludoclub rewards its
                      clients sure Levels according to the organization's circumspection and a
                      restrictive calculation that elements in different factors viz. number of tests
                      played, number of tests accurately replied, and so on. Mrludoclub Levels has no
                      financial worth and doesn't comprise cash or property of any sort. The quantity
                      of Levels procured and displayed in your Record doesn't comprise a true
                      equilibrium or mirror any put away worth, yet rather gauges the degree of your
                      restricted permit to utilize the Applications close by practicing not many
                      choices, for example, recovery of Levels for specific awards, capacity to take
                      part in specific games after arriving at least Level necessity. Mrludoclub's Levels
                      can't be sold or moved; nonetheless, it tends to be reclaimed for specific
                      extraordinary arrangements by making an ostensible installment, if material,
                      against those arrangements. Mrludoclub maintains all authority to pull out the
                      arrangement whenever during the residency of the administrations without earlier
                      insinuation. You recognize that you are not qualified for a discount for any
                      unused Levels or unused Virtual Things when Mrludoclub quits making an Application
                      accessible, whether such move is made at Mrludoclub's tact or because of
                      unanticipated occasions.</li>
                    <li>5.2. The cost payable by you is the cost, if relevant, showed in the actual
                      Application. At the point when you buy a permit to utilize our Administrations,
                      or buy a membership to utilize an Application, you consent to settle expenses,
                      demands and some other Legal/Govt. levy by anything name called, where
                      pertinent, that we or our representative surveys on your buy. We maintain
                      whatever authority is needed to change the cost and determinations displayed
                      corresponding to any Application, any membership, Levels, arrangements and
                      Things and so on. We offer no profits or abrogations of buys except if the
                      client fulfills any base exhibition rules that is chosen by Mrludoclub and referenced
                      on the Application.</li>
                    <h6>
                      <li>Concerning any contest or arrangement that is set in the Application, Mrludoclub
                        holds total privileges for the accompanying -- </li>
                    </h6>
                    <ul type="disc">
                      <li> -Choice relating to rivalry organization and content</li>
                      <li>-Choice relating to the valuing structure viz. Fundamental award, Incidental
                        award</li>
                      <li>-Choice relating to standards of determination of champs</li>
                      <li>-Choice relating to disbursal of prizes, gifts and arrangement recoveries
                      </li>
                    </ul>
                    <br />
                    Mrludoclub holds no liabilities against any type of distortion w.r.t. nature of the item,
                    coordinated operations and dealing with systems, whatever other unexpected expense
                    or horrible requirement that surfaces during the course of client asserting its
                    awards.
                    <h6>
                      <li> Usage of Mrludoclub</li>
                    </h6>
                    <ul type="disc">
                      <li> Any individual ("Client") getting to Mrludoclub or the Mrludoclub Application ('Mrludoclub
                        stage') for taking part in the different challenges and games, accessible on
                        Mrludoclub stage ("Contest(s)", "Competition") ('Mrludoclub Administrations') will be
                        limited by these Agreements, and any remaining guidelines, guidelines and
                        terms of purpose alluded to in this or given by Mrludoclub corresponding to any
                        Mrludoclub Administrations.</li>
                      <li>Mrludoclub will be qualified for alter these Agreements, rules, guidelines and
                        terms of purpose alluded to in this or given by Mrludoclub corresponding to any
                        Mrludoclub Administrations, whenever, by posting something very similar on Mrludoclub.
                        Utilization of Mrludoclub is the Client's acknowledgment of such Agreements,
                        rules, guidelines and terms of purpose alluded to in this or given by Mrludoclub
                        comparable to any Mrludoclub Administrations, as might be revised every once in a
                        while. Mrludoclub may, at its only carefulness, likewise tell the Client of any
                        change or adjustment in these Agreements, rules, guidelines and terms of
                        purpose alluded to in this or given by Mrludoclub, via sending an email to the
                        Client's enlisted email address or posting warnings in the Client accounts.
                        The Client may then practice the choices gave in such an email or notice to
                        show disapproval of the adjusted Agreements, rules, guidelines and terms of
                        purpose alluded to thus or given by Mrludoclub. On the off chance that such
                        choices are not practiced by the Client inside the time span endorsed in the
                        email or warning, the Client will be considered to have acknowledged the
                        changed Agreements, rules, guidelines and terms of purpose alluded to thus
                        or given by Mrludoclub.</li>
                      <li>Certain Mrludoclub Administrations being given on Mrludoclub might be dependent upon
                        extra principles and guidelines put down in that regard. To the degree that
                        these Agreements are conflicting with the extra circumstances put down, the
                        extra circumstances will win.</li>
                      <li>Mrludoclub may, at its sole and outright discretion:</li>
                      <ul type="circle">
                        <li> Limit, suspend, or end any Client's admittance to all or any piece of
                          Mrludoclub or Mrludoclub Stage Administrations;</li>
                        <li> Change, suspend, or end all or any piece of the Mrludoclub Stage
                          Administrations;</li>
                        <li> Reject, move, or eliminate any material that might be put together by a
                          Client;</li>
                        <li> Move or eliminate any happy that is accessible on Mrludoclub Stage;</li>
                        <li> Deactivate or erase a Client's record and all connected data and
                          documents on the record;</li>
                        <li> Lay out broad practices and cutoff points concerning utilization of
                          Mrludoclub Stage;</li>
                        <li> Relegate its freedoms and liabilities to all Client accounts hereunder
                          to any substance (post such task hint of such task will be shipped off
                          all Clients to their enlisted email ids)</li>
                      </ul>
                      <li> In the occasion any Client breaks, or Mrludoclub sensibly accepts that such
                        Client has penetrated these Agreements, or has illicitly or inappropriately
                        utilized Mrludoclub or the Mrludoclub Administrations, Mrludoclub may, at its sole and
                        outright watchfulness, and with next to no notification to the Client,
                        confine, suspend or end such Client's admittance to all or any piece of
                        Mrludoclub Challenges or the Mrludoclub Stage, deactivate or erase the Client's record
                        and all connected data on the record, erase any happy posted by the Client
                        on Mrludoclub and further, make specialized and legitimate strides as it
                        considers significant.</li>
                      <li>Assuming Mrludoclub charges its Clients a stage expense in regard of any Mrludoclub
                        Administrations, Mrludoclub will, immediately, reimburse such stage expense in
                        case of suspension or expulsion of the Client's record or Mrludoclub
                        Administrations by virtue of any carelessness or lack with respect to Mrludoclub,
                        yet not in the event that such suspension or evacuation is impacted due to:
                      </li>
                      <li>any break or lacking execution by the Client of any of these Agreements; or
                      </li>
                      <li>any conditions past the sensible control of Mrludoclub.</li>
                      <li>Clients agree to getting interchanges like declarations, authoritative
                        messages and promotions from Mrludoclub or any of its accomplices, licensors or
                        partners</li>
                    </ul>
                    <h6>
                      <li>Third Party Sites, Services and Products</li>
                    </h6>
                    <ul type="disc">
                      <li> Mrludoclub might contain connections to other Web locales possessed and worked
                        by outsiders. Clients' utilization of every one of those destinations is
                        dependent upon the circumstances, if any, posted by the locales. Mrludoclub
                        doesn't practice command over any Web locales separated from Mrludoclub and can't
                        be considered liable for any satisfied living in any outsider Web website.
                        Mrludoclub's incorporation of outsider substance or connections to outsider Web
                        locales isn't a support by Mrludoclub of such outsider Web webpage.</li>
                      <li>Clients' correspondence, exchanges/offers or related exercises with
                        outsiders, including installment suppliers and confirmation specialist
                        co-ops, are exclusively between the Client and that outsider. Clients'
                        correspondence, exchanges and use of the administrations/offers of such
                        outsider will be dependent upon the agreements, arrangements and other help
                        terms embraced/carried out by such outsider, and the Client will be
                        exclusively answerable for auditing a similar preceding executing or
                        profiting of the administrations/offers of such outsider. Client concurs
                        that Mrludoclub won't be capable or obligated for any misfortune or harm of any
                        kind caused because of any such exchanges/offers with outsiders. Any
                        inquiries, protests, or claims connected with any outsider item or
                        administration ought to be coordinated to the fitting merchant.</li>
                      <li>Mrludoclub contains content that is made by Mrludoclub as well as happy given by
                        outsiders. Mrludoclub doesn't ensure the precision, honesty, nature of the
                        substance given by outsiders and such happy may not depended upon by the
                        Clients in using the Mrludoclub Administrations gave on Mrludoclub incorporating while
                        at the same time taking part in any of the challenges facilitated on Mrludoclub.
                      </li>
                      <li>Mrludoclub has specific connections implanted to outsider administrations
                        including yet not restricted to YouTube. Your association with such outsider
                        stage/s are represented by their arrangements, and we ask you to audit their
                        approaches before you continue with profiting such administrations by means
                        of the contributions of Mrludoclub. The YouTube expressions are accessible at
                        https://www.youtube.com/t/terms; and the Google Protection Strategy is
                        accessible at https://policies.google.com/privacy?hl=en-US. Likewise, to
                        control your cooperation with Google account or their administrations
                        permits you to control your privileges and exercises, and might be gotten to
                        at https://myaccount.google.com/permissions?pli=1.</li>
                    </ul>
                  </ol>
                  <h4>
                    <li> Privacy Policy</li>
                  </h4>
                  <ol>
                    <li> 6.1. Any data that Clients give to Mrludoclub is dependent upon the Security
                      Strategy, which administers the assortment and utilization of the data. Clients
                      comprehend that through their utilization of the Administrations, they agree to
                      the assortment, use, and move of such data, as gone ahead in the Protection
                      Strategy, which shapes a fundamental piece of these Terms.</li>
                    <li>6.2. Mrludoclub will just gather, cycle, use and offer Clients data as per the
                      Security Strategy and as set out in these Terms. By utilizing the
                      Administrations, Clients give their agree to gathering, handling, utilizing and
                      offering to outsiders their own information in such manner. Clients who don't
                      consent to Protection Strategy shouldn't get to or potentially utilize the
                      Administrations. Any private data that might be gathered may likewise be
                      dependent upon the arrangement of any informal organization that Clients may
                      connected the Administrations to.</li>
                    <li>6.3. Clients recognize and acknowledge that they are likely to get incidental
                      interchanges, like assistance declarations and authoritative messages.</li>
                  </ol>
                  <h4>
                    <li> Scholarly Property</li>
                  </h4>
                  <ol>
                    <li>
                      Clients recognize that all copyright, brand names, and other protected
                      innovation privileges
                      in and connecting with the Administrations, is claimed by or authorized to
                      Mrludoclub. By
                      submitting Content on or through the Administrations, Clients award Mrludoclub an
                      around the
                      world, non-selective, eminence free permit - with the right to sublicense-to
                      utilize,
                      duplicate, repeat, process, adjust, change, distribute, communicate, show,
                      disseminate and
                      make subordinate works of such Happy in all possible media or circulation
                      techniques
                      currently known or later created.
                    </li>
                    <li>
                      Clients should not duplicate, convey, make accessible to the general population
                      or make any
                      subsidiary work from any Satisfied having a place with Mrludoclub as well as some
                      other Client.
                      Mrludoclub regards the licensed innovation privileges of others and anticipates that
                      Clients
                      should do likewise. Assuming Clients or any outsider accept that their Substance
                      has been
                      duplicated in a manner that comprises copyright encroachment, they will furnish
                      Mrludoclub with
                      the accompanying data: (I) a physical or electronic mark of the copyright
                      proprietor or an
                      individual approved to follow up for their sake; (ii) distinguishing proof of
                      the protected
                      work professed to have been encroached; (iii) ID of the material that is
                      professed to
                      encroach or to be the subject of encroaching action and that will be eliminated
                      or
                      admittance to which is to be handicapped, and data sensibly adequate to allow
                      the area of
                      the material; (iv) contact data, including address, phone number, and an email
                      address; (v)
                      an explanation that such Client or outsider have a completely honest intentions
                      conviction
                      that utilization of the material in the way griped of isn't approved by the
                      copyright
                      proprietor, its representative, or the law; and (vi) an explanation that the
                      data in the
                      warning is precise, and, under punishment of prevarication, that such Client or
                      outsider is
                      approved to follow up for the copyright proprietor.
                    </li>
                    <li>
                      Mrludoclub claims all authority to eliminate Content asserted to encroach without
                      earlier
                      notification, at its only watchfulness, and with no risk.
                    </li>
                  </ol>
                  <h4>
                    <li> Breach</li>
                  </h4>
                  <ol>
                    <li>
                      Mrludoclub holds the right consistently to eliminate or decline to incorporate,
                      convey and
                      additionally show any Happy on the Administrations, to suspend or end Clients,
                      and to
                      recover usernames as well as any channel name with practically no
                      responsibility. Mrludoclub
                      likewise maintains all authority to get to, read, safeguard, and reveal any data
                      as it
                      sensibly accepted is important to fulfill any pertinent regulation, guideline,
                      legitimate
                      interaction or administrative solicitation, implement the Terms, including
                      examination of
                      potential infringement about, identify, forestall, or in any case address
                      misrepresentation,
                      security or specialized issues, or to safeguard its privileges, property or
                      wellbeing, its
                      Clients and outsiders.
                    </li>
                    <li>
                      Clients will not do any of the accompanying while at the same time getting to or
                      utilizing
                      the Administrations: access, alter, or utilize non-public region of the
                      Administrations,
                      Mrludoclub's PC frameworks, or the specialized conveyance frameworks of Mrludoclub's
                      suppliers; test,
                      examine, break, track or potentially test the weakness of any framework or
                      organization, or
                      penetrate or evade any security or verification measures; access or search or
                      endeavor to
                      access or look through the Administrations using any and all means (mechanized
                      etc.) other
                      than through the right now accessible, distributed interfaces gave and simply as
                      per these
                      Terms, manufacture any TCP/IP bundle header or any piece of the header data in
                      any email or
                      posting, or in any capacity utilize the Administrations to send changed, tricky
                      or
                      misleading source-distinguishing data; or impede, or disturb, (or endeavor to do
                      as such),
                      the entrance of any client, host or organization, including, without impediment,
                      sending an
                      infection, over-burdening, flooding, spamming, mail-besieging the
                      Administrations, or by
                      prearranging the formation of Content in such a way as to obstruct or make an
                      unnecessary
                      weight on the Administrations. Clients will give exact and exceptional data
                      while making
                      their records as well as utilizing the Administrations. Accounts are restricted
                      at one for
                      every Client. The sell or move of any record or a piece of it, Content, things
                      as well as
                      any piece of the Administrations is completely precluded.
                    </li>
                    <li>
                      Mrludoclub maintains whatever authority is needed to suspend or end any admittance to
                      the
                      Administrations, including by ending or erasing any records and any Satisfied
                      related with
                      practically no notification or correspondence, assuming there is a sensibly
                      conviction that
                      such Client is in break of these Terms. In case of any break, Clients who caused
                      will repay
                      Mrludoclub for all misfortunes, mischief, claims and costs that might emerge.
                    </li>
                  </ol>
                  <h4>
                    <li> Disclaimers</li>
                  </h4>
                  <ol>
                    <li> 9.1. OK, title, and interest in and to the Administrations, as per the
                      arrangements set based on these Conditions, are and will stay the selective
                      property of Mrludoclub and its licensors. Administrations are safeguarded by
                      copyright, brand name, and different regulations. Nothing in the Terms gives
                      Clients any option to utilize the Mrludoclub name or any of the Mrludoclub brand names,
                      logos, area names, properties and other particular brand highlights.</li>
                    <li>9.2. These Terms will keep on applying until ended by either Clients or Mrludoclub as
                      follows: (I) Clients might end this lawful concurrence with Mrludoclub whenever under
                      any circumstance, by stopping the utilization of the Administrations and
                      reaching info@Mrludoclub.com to erase their record, given that they will in any case
                      be answerable for every one of the circumstances set in this Term for the time
                      they utilized the Administrations. Records might be deactivated or potentially
                      erased because of delayed dormancy; (ii) Mrludoclub might suspend or end Clients
                      records or stop furnishing with all or part of the Administrations whenever
                      under any circumstance, including, however not restricted to, assuming that
                      there is a sensibly conviction that such Clients have disregarded these Terms or
                      made a gamble or conceivable lawful openness; or on the other hand assuming that
                      at its own circumspection the arrangement of the Administrations is never again
                      financially as well as in fact suitable.</li>
                    <li> 9.3. Every one of the subsections beneath just applies up to the most extreme
                      degree allowed under relevant regulation. Nothing in this part is planned to
                      restrict any freedoms you might have which may not be legally restricted.</li>
                    <li> 9.4. The admittance to and utilization of the Administrations or any Satisfied
                      are notwithstanding the obvious danger ahead. Clients get it and concur that the
                      Administrations are given on an "with no guarantees" and "as accessible"
                      premise. Without restricting the prior, to the most extreme degree allowed under
                      relevant regulation, Mrludoclub renounces all guarantees and conditions, whether
                      express or suggested, of merchantability, readiness for a specific reason or
                      non-encroachment.</li>
                    <li> 9.5. Mrludoclub makes no guarantee and repudiate all obligation and responsibility
                      for: (I) the fulfillment, exactness, accessibility, practicality, security or
                      dependability of the Administrations or any Satisfied; (ii) any damage to
                      Clients' PC framework or cell phone working framework, loss of information, or
                      other mischief that outcomes from admittance to or utilization of the
                      Administrations or any Happy; (iii) the cancellation of, or the inability to
                      store or to send, any Happy and different correspondences kept up with by the
                      Administrations; and (iv) whether the Administrations will meet Clients
                      prerequisites or be accessible on a continuous, secure, or mistake free premise.
                      No exhortation or data, whether oral or composed, acquired from Mrludoclub or through
                      the Administrations, will make any guarantee not explicitly made in this.</li>
                    <li>9.6. The Administrations might contain connections to outsider sites or assets.
                      Clients recognize and concur that Mrludoclub isn't dependable or obligated for: (I)
                      the accessibility or exactness of such sites or assets; or (ii) the substance,
                      items, or administrations on or accessible from such sites or assets.
                      Connections to such sites or assets infer no underwriting by Mrludoclub of such sites
                      or assets or the substance, items, or administrations accessible from such sites
                      or assets. Clients recognize sole liability regarding and expect all hazard
                      emerging from its utilization of any such sites or assets.</li>
                    <li> 9.7. To the greatest degree allowed by material regulation, Mrludoclub will not be
                      responsible for any backhanded, coincidental, unique, significant or corrective
                      harms, or any deficiency of benefits or incomes, whether caused
                      straightforwardly or in a roundabout way or any deficiency of information, use,
                      kindness, or other elusive misfortunes, coming about because of (I) the
                      admittance to, utilization of, or failure to admittance to or utilize the
                      administrations; (ii) any lead or content of any outsider on the
                      Administrations, including without limit, any slanderous, hostile or unlawful
                      direct of different Clients or outsiders; (iii) any Satisfied got from the
                      Administrations; or (iv) unapproved access, use or change of Content; or (v) any
                      normal disasters.</li>
                    <li>9.8. In no occasion will the total risk of Mrludoclub surpass the aggregate sum that
                      such Client has paid to Mrludoclub in the half year time span finishing on the date
                      of the case, to a limit of 1,000 Indian Rupees (INR 1000). The impediments will
                      apply to any hypothesis of risk whether in view of guarantee, contract, rule,
                      misdeed (counting carelessness) etc., and whether Mrludoclub has been educated the
                      chance regarding any such harm, and regardless of whether a cure put forward in
                      this is found to have bombed its fundamental reason.</li>
                    <li> 9.9. The disappointment of Mrludoclub to uphold any right or arrangement of these
                      Terms won't be considered a waiver of such right or arrangement. If any
                      arrangement of these Terms is held to be invalid or unenforceable, then, at that
                      point, that arrangement will be restricted or killed to the base degree
                      fundamental, and the excess arrangements of these Terms will stay in full power
                      and impact.</li>
                    <li> 9.10. These terms will be represented by and understood as per the laws of
                      Nagaland Government as well as Republic of India. All cases, judicial actions or
                      suit emerging regarding the Administrations will be brought exclusively in the
                      significant courts situated in the city of Delhi/New Delhi - INDIA. Clients
                      agree to the purview of and setting in such courts and forgo any protest as to
                      awkward discussion.</li>
                    <li> 9.11. Mrludoclub is an expertise based Gaming Stage i.e... ability game which is
                      plainly characterized in the as of late passed by a Demonstration of
                      Administration of Nagaland under The Nagaland Preclusion of Betting and
                      Advancement and Guideline of Web based Talent based contests Act, 2015 (Act No.3
                      of 2016) and The Nagaland Restriction of Betting and Advancement and Guideline
                      of Web based Talent based contests Rules, 2016 in which Expertise Game and
                      permit to run/work of this kind of expertise games.</li><br />
                    According to Segment 2(3) of The Nagaland Restriction of Betting and Advancement and
                    Guideline of Web based Talent based contests Act, the meaning of "Talent based
                    contests" is duplicated as under,<br /><br />
                    "Talent based contests" will incorporate all such games where there is vast majority
                    of expertise over possibility, including where the ability connects with planning
                    the way of putting down bets or putting down wagers or where the ability lies in
                    group choice or determination of virtual stocks in light of examinations or where
                    ability connects with how the moves are made, whether through sending of physical or
                    mental ability or astuteness".<br /><br />
                    <h6>Explanation: With the end goal of this Demonstration </h6><br />
                    <li> -All Games gave in Timetable An of this Act will fall under the class of
                      "Talent based contests",</li>
                    <li>-'Games' which have been not entirely set in stone to be 'talent based contests'
                      by India or Worldwide courts or different rules, or games where there are
                      homegrown and global rivalries and competitions, or games which still up in the
                      air to be 'talent based contests' will additionally be qualified for be
                      remembered for Timetable A,</li>
                    <li>-Talent based contests might be (a) Card based and (b) activity/virtual
                      games/experience/secret and (c) estimation/Procedure/test based."</li><br />
                    High Court of India on account of Territory of Andhra Pradesh v. K Satyanarayana
                    (AIR 1968 SC 825) and KR Lakshmanan v. Territory of Tamil Nadu (AIR 1996 SC 1153)
                    wherein the word Talent based contests has plainly been expressed which is
                    duplicated as under,<br /><br />
                    "A talent based contest, then again albeit the component of chance fundamentally
                    can't be wiped out is one in which achievement relies primarily on the unrivaled
                    information, preparing consideration, experience and giftedness of the player. Golf,
                    chess and, surprisingly, Rummy are viewed as talent based contests. The Courts have
                    contemplated that there are not many games, if any, which comprises simply of
                    possibility or expertise, and as such shots in the dark is one in which the
                    component of chance prevails over the component of expertise, and a talent based
                    contest is one in which the component of ability prevails over the component of
                    possibility. It is prevailing component - "ability" or "possibility" - which decides
                    the personality of the game".<br /><br />
                    9.12. Mrludoclub Expertise Games, for stakes might be run all over India aside from the
                    Provinces of Assam, Odisha, Nagaland, Sikkim, Meghalaya, Andhra Pradesh, AND
                    Telangana as well as whatever other State which has restricted/precluded or to be
                    disallowed to run/work of Mrludoclub Ability Games. Mrludoclub Ability Games, being played
                    free of charge without the inclusion of cash/or any identical stakes might be run
                    all over India. Despite this, assuming any Client run as well as work the Mrludoclub
                    Expertise Games, he will be exclusively answerable for the equivalent.
                    9.13. Mrludoclub's ability based games are not Betting, Gaming and Lotteries.<br /><br />
                    9.14. These Terms, which incorporate the Protection Strategy, are the whole and
                    select understanding among Mrludoclub and Clients with respect to the Administrations,
                    barring any administrations for which Clients might have a different understanding
                    unequivocally what's more or instead of these Terms, and these Terms supplant and
                    supplant any earlier arrangements among Mrludoclub and Clients in regards to the
                    Administrations.<br /><br />
                    9.15. Mrludoclub might change these Terms occasionally, the latest form will constantly
                    be on the versatile application. Mrludoclub saves the right, yet not the commitment, in
                    its only attentiveness, to tell any change through email to the email related with
                    Clients account. Notwithstanding, by simply proceeding to access or utilize the
                    Administrations after those updates become successful, Clients consent to be limited
                    by the overhauled Terms. These Terms were keep going refreshed on sixth April,
                    2021.<br />
                  </ol>
                  <h4>
                    <li> Tax &amp; TDS Policy</li>
                  </h4>
                  10.1. Any expense/demands/obligation and so on, as material, will be paid by the client
                  of the round of Mrludoclub Games. <br /><br />
                  10.2. There is no TDS deducted as the triumphant sums depend on or under Rs.10,000/ - on
                  Mrludoclub Games/Test.<br /><br />
                  10.3. TDS is deducted on winning sums above Rs.10,000/ - on Mrludoclub Games/Test prior to
                  delivering the rewards prize/item/sum and additionally in anything that name
                  called.<br /><br />
                  10.4. In light of TDS (charge deducted at source) Rules for any kind of game dominating
                  in India, 30% TDS ought to be deducted on any wining above Rs.10,000/ - in a solitary
                  game.<br /><br />
                  10.5 The genuine cash added by you on the MrludoclubO Stage will be applied towards the
                  freedoms gave to you, by the Organization, to play any game/s. Such sum will be totally
                  non-refundable and non-transferrable and can and just be used towards the playing of
                  games on Mrludoclub Stage. The terms and states of each game would be shown, alongside the
                  fixed/variable rewards sum which you might win on cooperation and fruitful finishing of
                  the details of the game. Such sums will be chosen by the Organization for each game
                  independently. You would be qualified to pull out just to the degree of your rewards
                  (before that lapses) and after accommodation of significant documentation to the
                  Organization.<br /><br />
                  10.6. The individual answerable for paying will, prior to delivering the rewards, gather
                  the TDS sum from the victor.<br /><br />
                  10.7. Your award must be redeemable after TDS derivation , in the event that triumphant
                  item is over 10,000/ - . TDS sum is to be paid to Mrludoclub Games as furnished by Segment
                  194B read with Area 115BB of the Annual Expense Act, 1961.<br /><br />
                  10.8. The Client should give his substantial Dish card duplicate with the goal that
                  Mrludoclub Games can record TDS deducted in like manner.<br /><br />
                  10.9. TDS must be paid in 24 hours or less. Compassionately give us time span of 15
                  working days to convey your award.<br /><br />
                  10.10. To guarantee your triumphant item, if TDS as pertinent by govt. (according to
                  Segment 194B read with Area 115BB of the Personal Expense Act, 1961) on your triumphant,
                  champ gets 24 hrs time to pay TDS from the hour of winning to guarantee their award. In
                  the event that client neglects to pay TDS inside 24 hrs his/her triumphant will be
                  viewed as terminated.<br /><br />
                  10.11. Mrludoclub in light of a legitimate concern for lucidity, plays out a free shutting
                  balance affirmation toward the finish of each and every year from its outside wallet
                  specialist organizations (RazorPay, PayTM, Money Free, and so on.). If there exists
                  error between the records of the outside wallet specialist co-ops and Mrludoclub, Mrludoclub, in
                  its only tact and authority, will perform sensible expected level of effort to find out
                  the responsibility for the distinctions in the records.
                  <h4>
                    <li> Questions Integrity</li>
                  </h4>
                  <ul>
                    11.1. Mrludoclub endeavors to keep the data set of inquiries exceptional and make sure
                    that answers enlisted in the situation as the "right" answers are right.
                    Nonetheless, assuming there are questions that have responds to that are erroneous
                    as per a few sources, then, at that point, Mrludoclub holds no liability regarding the
                    result of the Test.
                    11.2. User Conduct <br /><br />
                    Clients consent to keep these Agreements and any remaining principles, guidelines
                    and terms of purpose of the Site. In the occasion Client doesn't maintain these
                    Agreements and any remaining standards, guidelines and terms of purpose, Mrludoclub may,
                    at its sole and outright carefulness, make a fundamental medicinal move, including
                    however not restricted to:<br /><br />
                    confining, suspending, or ending any Client's admittance to all or any piece of
                    Mrludoclub Administrations;<br /><br />
                    deactivating or erasing a Client's record and all connected data and documents on
                    the record. Any sum staying unused in the Client's Down record or Rewards Record on
                    the date of deactivation or erasure will be moved to the Client's ledger on record
                    with Mrludoclub subject to a handling expense (if any) relevant on such exchanges as set
                    out thus; or<br /><br />
                    abstaining from granting any prize(s) to such Client<br /><br />
                    Clients consent to give valid, precise, current and complete data at the hour of
                    enrollment and at any remaining times (as expected by Mrludoclub). Clients further
                    consent to refresh and keep refreshed their enrollment data.
                    A Client will not enlist or work more than one Client account with Mrludoclub.<br /><br />
                    <li> Clients consent to guarantee that they can get all correspondence from Mrludoclub by
                      stamping messages or sending SMSs from Mrludoclub as a component of their "protected
                      shippers" list. Mrludoclub will not be expected to take responsibility if any
                      email/SMS stays uninitiated by a Client because of such email getting conveyed
                      to the Client's garbage or spam envelope.</li>
                    <li>Any secret key gave by Mrludoclub to a Client may not be uncovered to any other
                      person. Clients may not utilize any other individual's secret word. Clients are
                      answerable for keeping up with the classification of their records and
                      passwords. Clients consent to advise Mrludoclub of any unapproved utilization of
                      their passwords or records or some other break of safety right away.</li>
                    <li>Clients consent to leave/log-out of their records toward the finish of every
                      meeting. Mrludoclub will not be answerable for any misfortune or harm that might
                      result assuming the Client neglects to consent to these necessities.</li>
                    <li>Clients make a deal to avoid utilizing cheats, takes advantage of, robotization,
                      programming, bots, hacks or any unapproved outsider programming intended to
                      change or obstruct Mrludoclub Administrations as well as Mrludoclub experience or aid such
                      movement.</li>
                    <li>Clients make a deal to avoid replicating, change, lease, rent, credit, sell,
                      relegate, circulate, figure out, award a security interest in, or in any case
                      move any right to the innovation or programming basic Mrludoclub or Mrludoclub's
                      Administrations.</li>
                    <li>Clients concur that without Mrludoclub's express composed assent, they will not
                      change or cause to be adjusted any records or programming that are essential for
                      Mrludoclub's Administrations.</li>
                    <li>Clients make a deal to avoid disturbing, overburden, or help or aid the
                      interruption or overburdening of (a) any PC or server used to offer or support
                      Mrludoclub or the Mrludoclub's Administrations (each a "Server"); or (2) the pleasure in
                      Mrludoclub Administrations by some other Client or individual.</li>
                    <li>Clients make a deal to avoid establishing, help or become engaged with an
                      assault, including without limit to circulation of an infection, disavowal of
                      administration, or different endeavors to disturb Mrludoclub Administrations or some
                      other individual's utilization or satisfaction in Mrludoclub Administrations.</li>
                    <li>Clients will not endeavor to acquire unapproved admittance to the Client
                      records, Servers or organizations associated with Mrludoclub Administrations using
                      any and all means other than the UI given by Mrludoclub, including however not
                      restricted to, by dodging or altering, endeavoring to evade or adjust, or
                      empowering or helping some other individual to avoid or change, any security,
                      innovation, gadget, or programming that underlies or is essential for Mrludoclub
                      Administrations.</li>
                    <li>Without restricting the previous, Clients make a deal to avoid involving Mrludoclub
                      for any of the accompanying.</li>
                    <li>To participate in any foul, hostile, disgusting, racial, collective, against
                      public, offensive, disparaging or harmful activity or correspondence;</li>
                    <li>To annoy, tail, undermine, or in any case disregard any legitimate privileges of
                      others;</li>
                    <li>To distribute, post, transfer, email, circulate, or spread (all in all,
                      "Communicate") any improper, profane, abusive, encroaching, disgusting, foul, or
                      unlawful substance;</li>
                    <li>To Communicate records that contain infections, debased documents, or some other
                      comparative programming or projects that might harm or unfavorably influence the
                      activity of someone else's PC, Mrludoclub, any product, equipment, or broadcast
                      communications gear;</li>
                    <li>To promote, proposition or sell any labor and products for any business reason
                      on Mrludoclub without the express composed assent of Mrludoclub;</li>
                    <li>To download any document, recompile or dismantle or in any case influence our
                      items that you know or sensibly ought to know can't be legitimately gotten in
                      such way;</li>
                    <li>To distort or erase any creator attributions, legitimate or other appropriate
                      notification or exclusive assignments or names of the beginning or the
                      wellspring of programming or other material;</li>
                    <li>To confine or restrain some other client from utilizing and partaking in any
                      open region inside our destinations;</li>
                    To gather or store individual data about different Clients;
                    <li>To slow down or upset Mrludoclub, servers, or organizations;</li>
                    <li>To mimic any individual or substance, including, yet not restricted to, a
                      delegate of Mrludoclub, or dishonestly state or in any case distort Client's
                      connection with an individual or element;</li>
                    <li>To produce headers or control identifiers or different information to mask the
                      beginning of any happy communicated through Mrludoclub or to control Client's
                      presence on Mrludoclub(s);</li>
                    <li>To distribute data that undermines the solidarity, uprightness, guard, security
                      or sway of India, well disposed relations with unfamiliar States, or public
                      request, or makes affectation the commission of any cognizable offense or
                      forestalls examination of any offense or is offending other country;</li>
                    <li>To distribute data that is an attack of another's protection, their substantial
                      security, annoying or pestering based on orientation, derogatory, racially or
                      ethnically shocking, relating or empowering tax evasion or betting, or generally
                      conflicting with or in spite of the regulations in force;</li>
                    To make any move that forces an absurdly or lopsidedly enormous burden on our
                    framework;
                    <li>To take part in any criminal operations. You consent to utilize our notice board
                      administrations, talk regions, news gatherings, discussions, networks as well as
                      message or correspondence offices (aggregately, the "Discussions") just to send
                      and get messages and material that are appropriate and connected with that
                      specific Discussion.</li>
                    <li>On the off chance that a Client picks a username that, as Mrludoclub would like to
                      think is profane, revolting, oppressive or that could expose Mrludoclub to public
                      criticism or disdain, Mrludoclub holds the right, without earlier notification to the
                      Client, to change such username and personal the Client or erase such username
                      and posts from Mrludoclub, deny such Client admittance to Mrludoclub, or any blend of
                      these choices.</li>
                    <li>Unapproved admittance to Mrludoclub is a break of these Agreements, and an
                      infringement of the law. Clients make a deal to avoid getting to Mrludoclub using any
                      and all means other than through the connection point that is given by Mrludoclub to
                      use in getting to Mrludoclub. Clients make a deal to avoid utilizing any computerized
                      implies, including, without impediment, specialists, robots, contents, or bugs,
                      to access, screen, or duplicate any piece of our locales, aside from those
                      robotized implies that we have endorsed ahead of time and recorded as a hard
                      copy.</li>
                    <li>
                    </li>
                    <li>Clients recognize and acknowledge that they are liable to get periodic
                      correspondences, comparable to their commitment to agree with the Agreements,
                      rules and guidelines, Protection Strategy or some other client record to get to
                      the Administrations and Stage presented by nm . In case of rebelliousness, Mrludoclub
                      maintains whatever authority is needed to end the Client's entrance or
                      utilization freedoms to the Administrations and Stage presented by Mrludoclub.</li>
                    <li>Utilization of Mrludoclub is likely to existing regulations and legitimate cycles.
                      Nothing contained in these Agreements will restrict Mrludoclub's all in all correct
                      to follow administrative, court, and policing or necessities connecting with
                      Clients' utilization of Mrludoclub.</li>
                    Clients might connect with Mrludoclub through -
                    <li>Helpdesk on the off chance that the client has any worries as to a match or
                      potentially challenge inside 48 (48) hours of victor statement for the concerned
                      challenge.</li>
                    <li>People beneath the age of eighteen (18) years are not permitted to take an
                      interest on any of the challenges, games (by anything name approached) the Mrludoclub
                      Stage. The Clients should uncover their genuine age at the hour of getting
                      access into the Mrludoclub Stage.</li>
                    <li>Mrludoclub may not be considered answerable for any happy contributed by Clients on
                      the Mrludoclub.</li>
                  </ul>
                  <h4>
                    <li> Payment Terms</li>
                  </h4>
                  <ul>
                    <li /> In regard of any exchanges went into on the Mrludoclub stage, including making
                    an installment to take part in the paid renditions of Contest(s), Clients consent to
                    be limited by the accompanying installment terms:
                    <li>The installment of pre-assigned sum Clients make to take part in the Contest(s)
                      is comprehensive of the pre-assigned stage expense (when appropriate) for
                      admittance to the Mrludoclub Administrations charged by Mrludoclub and pre-decided
                      member's commitment towards prize cash pool.</li>
                    <li>Dependent upon these Agreements, all sums gathered from the Client are held,
                      until assurance of the Champs and circulation of prizes. Mrludoclub gets just its
                      portion of the stage Charges, dependent upon appropriateness.</li>
                    <li>The Client might take part in a Challenge wherein the Client needs to contribute
                      a pre-determined commitment towards the Award Cash Pool of such Challenge, which
                      will be given to the Winner(s) of the Challenge after the fruition of the
                      Challenge according to the agreements of such Challenge. It is explained that
                      Mrludoclub has no right or premium in this Prize Cash Pool, and just goes about as a
                      middle person participated in gathering and conveying the Award Cash Pool as per
                      the Challenge agreements. The sum to be paid-in by the Client towards the Award
                      Cash Pool would likewise be charged from the Client's record balance.</li>
                    <li>Any client benefiting Mrludoclub administrations are furnished with two classes of
                      records for the handling and compromise of installments: (I) 'Unplayed' Record,
                      (ii) Rewards Record. It is explained that in no occasion the exchange of any
                      sums in the Client's records to some other class of record held by the client or
                      any outsider record, including a ledger held by an outsider:</li>
                    <li>Client's rewards can be credited in any wallet sub-area (Stores, Extra, Rewards)
                      in any extent at Mrludoclub's only watchfulness.</li>
                    <li>Client's transmitting the sum the assigned installment door will be credited to
                      Client's 'Unplayed' Record'.</li>
                    <li>Each time a Client partakes in any challenge on Mrludoclub stage, the pre-assigned
                      sum will be charged in the Client's record. In charging sums from the Client's
                      records towards the pre-assigned measure of such client will be charged from the
                      Client's Unplayed Record and from that point, any leftover measure of
                      cooperation expense will be charged from the Client's Triumphant Record.</li>
                    <li>In the event that there is any sum still needing to be paid by the Client
                      comparable to such Client's cooperation in any match(s) or Contest(s), the
                      Client will be taken to the assigned installment door to give impact to such
                      installment. In the event that any sum added by the Client through such
                      installment door surpasses the leftover measure of the pre-assigned sum, the sum
                      in overabundance will be moved to the Client's 'Unplayed' Record and will be
                      accessible for use in support in any match(s) or Contest(s) or for withdrawal as
                      per these Agreements.</li>
                    <li>Charges from the 'Unplayed' Record to empower a client's support in a Challenge
                      will be made arranged by the date of credit of sums in the 'Unplayed' Record,
                      and likewise sums credited into 'Unplayed' Record prior in time will be charged
                      first.</li>
                    <li>A Client will be allowed to pull out any sums credited into such Client's
                      'Unplayed' Record under any circumstance by reaching Mrludoclub Client care. All sums
                      credited into a Client's 'Unplayed' Record should be used in no less than 60
                      days of credit. On the off chance that any unutilised sum lies in the 'Unplayed'
                      Record after the finish of 60 days from the date of credit of such sum, Mrludoclub
                      maintains all authority to relinquish such unutilized sum, without risk or
                      commitment to pay any pay to the Client. Clients are mentioned to take note of
                      that they will be expected to give legitimate photograph ID and address
                      verification records for confirmation of personality and address for Mrludoclub to
                      handle the withdrawal demand. The name referenced on the Client's photograph
                      distinguishing proof archive ought to relate with the name given by the Client
                      at the hour of enrollment on Mrludoclub, as well as the name and address existing in
                      the records of the Client's ledger as given to Mrludoclub. If no financial balance
                      has been enrolled by the Client against such Client's record with Mrludoclub, or the
                      Client has not checked his/her Client account with Mrludoclub, agreeable to Mrludoclub and
                      as per these Agreements, Mrludoclub will give such Client a warning to the Client's
                      email address as on record with Mrludoclub no less than 30 days preceding the
                      Solicitation Date, and in the event that the Client neglects to enlist a ledger
                      with his/her Client Record or potentially to confirm his/her Client Record by
                      the Solicitation date, Mrludoclub will be qualified for relinquish any sums subject
                      to move. Inability to furnish Mrludoclub with a substantial financial balance or
                      legitimate distinguishing proof records (agreeable to Mrludoclub) may bring about the
                      relinquishment of any sums subject to move as per this proviso.</li>
                    <li>Withdrawal of any sum remaining to the Client's credit in the Rewards Record
                      might be made via a solicitation to Mrludoclub yet the sum will be relinquish upon
                      finish of 60 days from the date of credit of such sum in the Client's Rewards
                      Record endless supply of 60 days of in-movement.</li>
                    <li>Further, to lead special exercises, Mrludoclub may unwarrantedly issue Extra focuses
                      (Called as Money Reward) to the Client with the end goal of support in any
                      Contest(s) and no Client will be allowed to move or demand the exchange of any
                      sum in to the Money Reward . The utilization of any focuses gave will be
                      dependent upon the limits and limitations, including without impediment,
                      limitations as to time inside which such focuses should be utilized, as applied
                      by Mrludoclub and advised to the Client at the hour of issue of such sum. The issue
                      of any focuses to the client is dependent upon the sole circumspection of Mrludoclub
                      and can't be requested by any Client as an issue of right. The issue of any such
                      sum by Mrludoclub on any day will not qualifies the client for request the issuance
                      of such sum at any resulting period in time nor make an assumption for repeating
                      issue of such sum by Mrludoclub to such Client. The extra focuses/Money Reward
                      allowed to the client might be involved by such Client to set off against the
                      commitment to value pool in any Challenge, as per these Agreements. The extra
                      focuses will not be pull out capable or transferrable to some other record of
                      the Client, including the financial balance of such Client, or of some other
                      Client or individual, other that as a feature of the rewards of a Client in any
                      Contest(s). In the event that the Client ends his/her record with Mrludoclub or such
                      record whenever ended by Mrludoclub, all extra focuses conceded to the client will
                      get back to Mrludoclub and the Client will not have any right or interest on such
                      focuses.</li>
                    <li>All money reward credited in the Client account will be legitimate for a time of
                      14 days from the date of credit. The money reward will pass toward the finish of
                      14 days and the money reward sum will not reflect in the Client account.</li>
                    Clients concur that once they affirm an exchange on Mrludoclub, they will be limited by
                    and make installment for that exchange.
                    <li>The Client recognizes that subject to time taken for bank compromises and such
                      other outer conditions that Mrludoclub hosts on third get-togethers, any exchanges on
                      Mrludoclub Stage might require as long as 24 hours to be handled. Any sum paid or
                      moved into the Client's 'Unplayed' Record or Rewards Record might require as
                      long as 24 hours to reflect in the Client's 'Unplayed' Record or Rewards Record
                      balance. Likewise, the usage of the extra places or cash charged from 'Unplayed'
                      Record or Rewards Record might require as long as 24 hours to reflect in the
                      Client's 'Unplayed' Record or Rewards Record balance. Clients make a deal to
                      avoid raising any grumbling or guarantee against Mrludoclub in regard of any
                      postponement, including any lost an open door to join any Challenge or match
                      because of defer in crediting of exchange sum into any of the Client's records.
                    </li>
                    <li>An exchange, once affirmed, is conclusive and no wiping out is reasonable. Mrludoclub
                      may, in specific excellent conditions and at its sole and outright prudence,
                      discount the sum to the Client subsequent to deducting relevant abrogation
                      charges and expenses. At the hour of the exchange, Clients may likewise be
                      expected to observe specific extra agreements and such extra agreements will
                      likewise administer the exchange. To the degree that the extra agreements
                      contain any statement that is clashing with the current agreements, the extra
                      agreements will win.</li>
                    <li>The genuine cash included by the Client Mrludoclub will exclusively be applied
                      towards the freedoms gave to you, by the Organization, to play any game/s. Such
                      sum will be totally non-refundable and non-transferrable and can and just be
                      used towards the playing of games on Mrludoclub. The T&amp;C of each game would be
                      shown, alongside the fixed/variable rewards sum which the Client might win on
                      interest and effective fruition of the particulars of the game. Such sums will
                      be chosen by the Organization for each game independently. The Client would be
                      qualified to pull out just to the degree of their rewards (before that lapses)
                      and after accommodation of important documentation to the Organization.</li>
                  </ul>
                  <h5>12.1. Release and Limitations of Liability</h5>
                  <ul>
                    <li> Users shall access the Mrludoclub Services provided on Mrludoclub voluntarily and at
                      their own risk. Mrludoclub shall, under no circumstances be held responsible or
                      liable on account of any loss or damage sustained (including but not limited to
                      any accident, injury, death, loss of property) by Users or any other person or
                      entity during the course of access to the Mrludoclub Services (including
                      participation in the Contest(s)) or as a result of acceptance of any prize.</li>
                    <li>By entering the contests and accessing the Mrludoclub Services provided therein,
                      Users hereby release from and agree to indemnify Mrludoclub, and/ or any of its
                      directors, employees, partners, associates and licensors, from and against all
                      liability, cost, loss or expense arising out their access to the Mrludoclub Services
                      including (but not limited to) personal injury and damage to property and
                      whether direct, indirect, consequential, foreseeable, due to some negligent act
                      or omission on their part, or otherwise.</li>
                    <li>Mrludoclub accepts no liability, whether jointly or severally, for any errors or
                      omissions, whether on behalf of itself or third parties in relation to the
                      prizes.</li>
                    <li>Users shall be solely responsible for any consequences which may arise due to
                      their access of Mrludoclub Services by conducting an illegal act or due to
                      non-conformity with these Terms and Conditions and other rules and regulations
                      in relation to Mrludoclub Services, including provision of incorrect address or other
                      personal details. Users also undertake to indemnify Mrludoclub and their respective
                      officers, directors, employees and agents on the happening of such an event
                      (including without limitation cost of attorney, legal charges etc.) on full
                      indemnity basis for any loss/damage suffered by Mrludoclub on account of such act on
                      the part of the Users.</li>
                    <li>Users shall indemnify, defend, and hold Mrludoclub harmless from any third
                      party/entity/organization claims arising from or related to such User's
                      engagement with the Mrludoclub or participation in any Contest. In no event shall
                      Mrludoclub be liable to any User for acts or omissions arising out of or related to
                      User's engagement with the Mrludoclub or his/her participation in any Contest(s).
                    </li>
                    <li>In consideration of Mrludoclub allowing Users to access the Mrludoclub Services, to the
                      maximum extent permitted by law, the Users waive and release each and every
                      right or claim, all actions, causes of actions (present or future) each of them
                      has or may have against Mrludoclub, its respective agents, directors, officers,
                      business associates, group companies, sponsors, employees, or representatives
                      for all and any injuries, accidents, or mishaps (whether known or unknown) or
                      (whether anticipated or unanticipated) arising out of the provision of Mrludoclub
                      Services or related to the Contests or the prizes of the Contests.</li>
                  </ul>
                  <h5>12.2. Standard Terms and Conditions of Promotions</h5>
                  These standard agreements of advancements ("Standard Terms") supplement the terms of
                  advancements embraced on the Mrludoclub site and which reference these Standard Expressions
                  (each a "Advancement"):
                  <ul>
                    <li> Cooperation in any Advancement will be dependent upon a client consenting to
                      the Advancement Expressions carried out by Mrludoclub in regard of such Advancement
                      ("Advancement Terms") and these Standard Expressions. By taking part in any
                      Advancement, the member further agrees to and consents to stick with the
                      agreements of the Mrludoclub game and Mrludoclub's protection strategy.</li>
                    <li>The Advancements are simply open to clients in India. Cooperation in the
                      Advancements as a substitute isn't allowed.</li>
                    Cooperation in the Advancements is willful.<br /><br />
                    <li>Cooperation in one Advancement doesn't ensure that such client will be qualified
                      to take part in another Advancement.</li>
                    <li>A client might take part in an Advancement and profit of every Advancement just
                      through one record. A current client of Mrludoclub will not enroll another record or
                      work more than one client account with Mrludoclub or take part in an Advancement by
                      enlisting another record.</li>
                    <li>Clients meaning to partake in an Advancement might be expected to confirm their
                      versatile number and other record subtleties as per the Advancement Expressions
                      for such Advancement.</li>
                    <li>People meaning to take, a recorded their part in an Advancement telephone
                      numbers on the Public Don't Call Library ("NDNC Vault"), will de-register
                      themselves from the NDNC Vault till the fulfillment of such Advancement
                      (counting the conveyance of Reward Sum (if any) or the free-passage (if any)
                      under such Advancement). Such people make a deal to avoid making any case or
                      raise any protest at all against Mrludoclub in this regard. Kindly note that people
                      planning to partake a not de-enlisted in an Advancement themselves from the NDNC
                      Library will likewise reserve no option to make any case or raise any objections
                      against Mrludoclub in the event that they do or get no call or SMS concerning their
                      cooperation and any remaining issues relating to an Advancement.</li>
                    <li>The check interaction might expect you to submit individual data about yourself.
                      You consent to get correspondence from Mrludoclub. Any data gathered in regard of
                      your personality and contact subtleties as a feature of an Advancement or
                      generally throughout your utilization of the Mrludoclub Site will be likely to
                      Mrludoclub's Security Strategy.</li>
                    <li>Mrludoclub may, at its sole and outright tact, preclude any client from an
                      Advancement in the event that such client participates in or it is found that
                      such client has participated in any unlawful, unlawful or ill-advised lead (with
                      respect to any of the Advancements etc.).</li>
                    <li> The Reward Sum (if any) stored into the client's record can be utilized to join
                      cash endlessly challenges on Mrludoclub. Be that as it may, the Reward Sum (if any)
                      can't be: (I) used to join 2-part challenges; or (ii) removed or moved to some
                      other money balance account held by you with Mrludoclub or to any outsider record or
                      to any bank/installment instrument account. THE Reward Sum (IF ANY) Will Lapse
                      AND BE WITHOUT Impact Toward THE Finish OF FOURTEEN DAYS FROM THE DATE OF CREDIT
                      OF THE Reward Sum (IF ANY).</li>
                    <li> The store of the Reward Sum (if material) or the award of the free-section (if
                      any) will be at the sole watchfulness of Mrludoclub and will be dependent upon the
                      client's consistence with these Standard Expressions and the pertinent
                      Advancement Terms. Mrludoclub might substitute or change the Reward Amount(if any) or
                      free-passage (if any) presented under an Advancement whenever without notice.
                      Clients may not substitute Reward Sum (if any) or free-passage (if any) for
                      different things or trade for cash.</li>
                    <li>Mrludoclub claims all authority to keep or relinquish the advantages of an
                      Advancement (counting a free-passage or Reward Sum because of a member or any
                      awards/rewards procured by the member by utilizing such advantages) if it
                      decides or sensibly accepts that the taking an interest client has disregarded
                      these Standard Expressions, the pertinent Advancement Terms or the agreements of
                      the Mrludoclub dream game(s).</li>
                    <li> Simple support in an Advancement doesn't qualifies the member for get any
                      free-passage or Reward Amount(s) demonstrated as an award under such
                      Advancement.</li>
                    <li>The choice of Mrludoclub will be conclusive and restricting as for the Advancements,
                      and the awards in that and no correspondence, protest, grumblings, and so on
                      will be engaged in such manner.</li>
                    Every Advancement can't be clubbed with any of other challenge/offer/advancement
                    that are running at the same time and coordinated or directed by Mrludoclub.<br /><br />
                    <li>Mrludoclub maintains all authority to change/alter/or pull out any of the
                      Advancements or potentially change these Standard Expressions and additionally
                      the Advancement Expressions with no earlier notification of the equivalent at
                      its only watchfulness.</li>
                    <li> Mrludoclub commits no responsibility, express or inferred, to answer any criticism,
                      idea and, or, inquiries of the members of the Advancements.<br /><br />
                    </li>
                    <li> Despite anything contained thus, the total risk of Mrludoclub to a taking part
                      client corresponding to any Advancement under any condition will not surpass Rs.
                      50.</li>
                    The Advancements will be represented by the laws of the Republic of India, and any
                    debates or conflicts in regard of this Advancement will be dependent upon the
                    selective ward of the courts of Delhi. <br /><br />
                  </ul>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Termcondition;