
import React from "react";
import logos from '../images/mrludo.png'
import chrome from '../images/global-chrome.png'

const Rightsidebar = () => {
    return (
        <div className="">
            <div className="divider-y" />
            <div className="rightContainer bg_right">
                <div className="rcBanner flex-center">
                    <picture className="rcBanner-img-container">
                        <img src={logos} alt="" title="mrludoclub Ludo" width="100px" hight="20px" />
                    </picture>
                    <div className="rcBanner-text">mrludoclub <span className="rcBanner-text-bold">Win Real Cash!</span></div>
                    <div className="rcBanner-footer">For best experience, open&nbsp;<a href="#!" style={{ color: 'rgb(44, 44, 44)', fontWeight: 500, textDecoration: 'none' }}>mrludoclub.com</a>&nbsp;on&nbsp;<img src={chrome} alt="" />&nbsp;chrome mobile</div>
                </div>
            </div>
        </div>
    );
}
export default Rightsidebar;