import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Addfund = () => {
  const [usserid, setUserId] = useState('');
  const [inputValuedeposit, setInputValuedeposit] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem("userid");
    setUserId(userId);
  }, []);

  useEffect(() => {
    fetchGeneralSettings();
  }, []);

  const fetchGeneralSettings = async () => {
    try {
      const token = localStorage.getItem("token_store_vplay");
      const response = await axios.post(
        'https://api.mrludoclub.com/api/users/get-genral-setting',
        {},
        { headers: { 'x-access-token': token } }
      );
      const data = response.data;
      if (data && data.length > 0) {
        // setUsers(data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    const amount = parseFloat(inputValuedeposit);
    if (amount >= 10 && amount <= 20000) {
      navigate('/Deposit-Amount');
      localStorage.setItem('deposit_amount', inputValuedeposit);
    } else {
      alert('Please enter an amount between ₹10 and ₹20,000.');
    }
  };

  return (
    <div className="leftContainer">
      <div className="px-4 py-3">
        <div className="games-section">
          <p>ध्यान दें पैसा जोड़ते समय ओहके का इंतजार करे ! धन्यवाद</p>
          <p>Payment Add Karte Time Ok Hone Tak Ka Wait Karen ! Thanks</p>
          <div className="d-flex position-relative align-items-center">
            <div className="games-section-title">Choose amount to add</div>
          </div>
        </div>
        <form>
          <div className="pb-3">
            <div className="MuiFormControl-root mt-4 MuiFormControl-fullWidth">
              <div className="MuiFormControl-root MuiTextField-root">
                <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink" data-shrink="true">Enter Amount</label>
                <div className="MuiInputBase-root MuiInput-root MuiInput-underline jss1 MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                  <input
                    type="number"
                    onChange={(e) => setInputValuedeposit(e.target.value)}
                    required
                    name="amount"
                    className="form-control border-bottom"
                    placeholder="₹"
                    style={{ border: 'none' }}
                    value={inputValuedeposit}
                  />
                  <input
                    type="hidden"
                    value={usserid}
                    name="userid"
                    className="form-control border-bottom"
                  />
                </div>
                <p className="MuiFormHelperText-root">Min: 10, Max: 20000</p>
              </div>
            </div>
           
            <div className="games-window">
                <div className="gameCard-container" id="amount1">
                  <button type="button" className="add-fund-box font_rupee" onClick={() => setInputValuedeposit("100")}>
               
                    <span style={{ fontSize: '0.9em' }}>₹</span>100
                  </button>
                </div>
                <div className="gameCard-container" id="amount2">
               
                  <button type="button" className="add-fund-box font_rupee" onClick={() => setInputValuedeposit("250")}>
                
                    <span style={{ fontSize: '0.9em' }}>₹</span>250
                  </button>
                </div>
                <div className="gameCard-container" id="amount3">
               
                  <button type="button" className="add-fund-box font_rupee" onClick={() => setInputValuedeposit("500")}>
                 
                    <span style={{ fontSize: '0.9em' }}>₹</span>500
                  </button>
                </div>
                <div className="gameCard-container" id="amount4">
               
                  <button type="button" className="add-fund-box font_rupee" onClick={() => setInputValuedeposit("1000")}>
                  
                    <span style={{ fontSize: '0.9em' }}>₹</span>1000
                  </button>
                </div>
              </div>
          </div>
          <div className="refer-footer">
            <button
              disabled={!inputValuedeposit}
              type="button"
              className="disabledButton btn btn-primary btn-lg"
              style={{ width: '100%' }}
              onClick={handleClick}
            >
              NEXT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Addfund;
