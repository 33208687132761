
import { React, useState,useEffect } from "react";
import axios from "axios";
import logos from '../images/mrludo.png'
import logo1 from '../images/game-image.jpg'
import logo2 from '../images/snakeandladder.jpg'
import logo3 from '../images/global-battleIconWhiteStroke.png'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Outlet, Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";
import PWAInstallerPrompt from 'react-pwa-installer-prompt';
function Deshbord() {
    const [genralsettings, setgenralsetting] = useState([]);
    const [Game1, setGame1] = useState([]);
    const [Game2, setGame2] = useState([]);
    const [Game3, setGame3] = useState([]);
    const [Game4, setGame4] = useState([]);
    useEffect(
        () => {
          genralsetting();
        }, [true]
      );
      const genralsetting = async () => {
          const token = localStorage.getItem("token_store_vplay");
        //    let headers = new Headers();

        // headers.append('Content-Type', 'application/json');
        // headers.append('Accept', 'application/json');

        // headers.append('Access-Control-Allow-Origin', 'http://localhost:8081');
        // headers.append('Access-Control-Allow-Credentials', 'true');

        // headers.append('GET', 'POST', 'OPTIONS');

        // headers.append('x-access-token', token);
        var config = {
          method: 'post',
          url: 'https://api.mrludoclub.com/api/users/get-genral-setting',
        //   headers: headers,
          headers: {
            'x-access-token': token
          },
        };
    
        axios(config)
          .then(function (response) {
            const res = JSON.stringify(response.data);
            const objectRes = JSON.parse(res);
            setgenralsetting(objectRes.data[0]);
            setGame1(objectRes.game1);
            setGame2(objectRes.game2);
            setGame3(objectRes.game3);
            setGame4(objectRes.game4);
    
          })
          .catch(function (error) {
            console.log(error);
          });
    
    }
    
    const [deferredPrompt, setDeferredPrompt] = useState(null);
      const [showInstallButton, setShowInstallButton] = useState(true); 

      useEffect(() => {
        window.addEventListener('beforeinstallprompt', (event) => {
          event.preventDefault();
          setDeferredPrompt(event);
        });
      
        return () => {
          window.removeEventListener('beforeinstallprompt', (event) => {
            event.preventDefault();
            setDeferredPrompt(event);
          });
        };
      }, []);
      
      const handleInstallClick = () => {
        if (deferredPrompt !== null) {
          deferredPrompt.prompt();
      
          deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt');
              setShowInstallButton(false); 
            } else {
              console.log('User dismissed the A2HS prompt');
            }
      
            setDeferredPrompt(null);
          });
        }
      };
    return (
        <>

            <div class="leftContainer">
               <div className="main-area">
                    <div className="collapseCard-container">
                        <div className="btninstall">
                                                {showInstallButton && <button style={{background:'#f38d2a' }} className="btn-install  otp_login submit_data" onClick={handleInstallClick}>Install Web Application</button>}

                       </div> 
                        <Marquee>
                        {genralsettings.top_heading}
                        </Marquee>
                        <div className="collapseCard">
                            <h5>Mr LudoClub</h5>
                        </div>
                    </div>
                </div>

                <section className="games-section p-3">
                    <div className="d-flex align-items-center games-section-title">Our Games</div>
                    <div className="games-section-headline mt-2 mb-1">
                        <div className="games-window">
                            <div className="gameCard-container">
                                <span className="blink text-danger d-block text-right">◉ LIVE</span>
                                {Game1 == 1 ? 
                                <Link className="gameCard" to="/Ludo-Classic">
                 
                                    <picture className="gameCard-image">
                                        <img width="100%" src={logo1} alt="" />
                                    </picture>
                                    <div className="gameCard-title">Ludo Classic Sidha</div>
                                    <picture className="gameCard-icon">
                                        <img src={logo3} alt="" />
                                    </picture>
                                </Link>
                                :
                                <Link className="gameCard" to="/">
                 
                                <picture className="gameCard-image">
                                    <img width="100%" src={logo1} alt="" />
                                </picture>
                                <div className="gameCard-title">Ludo Classic Sidha</div>
                                <picture className="gameCard-icon">
                                    <img src={logo3} alt="" />
                                </picture>
                            </Link>
                                }
                            </div>
                            <div className="gameCard-container">
                                <span className="blink text-danger d-block text-right">◉ LIVE</span>
                                {Game2 == 1 ? 
                                <Link className="gameCard" to="/Ludo-Ulta">
                                    <picture className="gameCard-image">
                                        <img width="100%" src={logo1} alt="" />
                                    </picture>
                                    <div className="gameCard-title">Ludo Classic Ulta</div>
                                    <picture className="gameCard-icon">
                                        <img src={logo3} alt="" />
                                    </picture>
                                </Link>
                                :
                                <Link className="gameCard" to="/">
                                <picture className="gameCard-image">
                                    <img width="100%" src={logo1} alt="" />
                                </picture>
                                <div className="gameCard-title">Ludo Classic Ulta</div>
                                <picture className="gameCard-icon">
                                    <img src={logo3} alt="" />
                                </picture>
                            </Link>
                            }
                            </div>
                            {/* <div className="gameCard-container">
                                <span className="blink text-danger d-block text-right">◉ LIVE</span>
                                {Game3 == 1 ? 
                                <Link className="gameCard" to="/Ludo-Popular">
                                    <picture className="gameCard-image">
                                        <img width="100%" src={logo1} alt="" />
                                    </picture>
                                    <div className="gameCard-title">Ludo Popular</div>
                                    <picture className="gameCard-icon">
                                        <img src={logo3} alt="" />
                                    </picture>
                                </Link>
                                :
                                <Link className="gameCard" to="/">
                                <picture className="gameCard-image">
                                    <img width="100%" src={logo1} alt="" />
                                </picture>
                                <div className="gameCard-title">Ludo Popular</div>
                                <picture className="gameCard-icon">
                                    <img src={logo3} alt="" />
                                </picture>
                            </Link>
                                }
                            </div>
                            <div className="gameCard-container">
                                <span className="blink text-danger d-block text-right">◉ LIVE</span>
                                {Game4 == 1 ? 
                                <Link className="gameCard" to="/Snake-and-Ladder-Sidha">
                                    <picture className="gameCard-image">
                                        <img width="100%" src={logo2} alt="" />
                                    </picture>
                                    <div className="gameCard-title">Snake and Ladder Sidha</div>
                                    <picture className="gameCard-icon">
                                        <img src={logo3} alt="" />
                                    </picture>
                                </Link>
                                :
                                <Link className="gameCard" to="/">
                                <picture className="gameCard-image">
                                    <img width="100%" src={logo2} alt="" />
                                </picture>
                                <div className="gameCard-title">Snake and Ladder Sidha</div>
                                <picture className="gameCard-icon">
                                    <img src={logo3} alt="" />
                                </picture>
                            </Link>
                                }
                            </div> */}
                        </div>
                    </div>
                </section>

                <section className="footer">
                    <div className="footer-divider" />

                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">


                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"

                                >
                                    <Typography>

                                        <a className="px-3 py-4 d-block" href="#!" style={{ textDecoration: 'none' }}>
                                            <picture className>

                                                <img src={logos} alt="" width="100px" hight="20px" />
                                            </picture>
                                            <span style={{ color: 'rgb(149, 149, 149)', fontSize: '0.8em', fontWeight: 400 }}> . Terms, Privacy,
                                                Support</span><br />

                                            <span style={{ color: 'rgb(149, 149, 149)', fontSize: '0.8em', fontWeight: 400 }}><strong>The Game is
                                                ON</strong></span>
                                        </a>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="row footer-links">
                                            <Link className="col-6" to="/term-condition">Terms &amp; Condition</Link>
                                            <Link className="col-6" to="/privacy-policy">Privacy Policy</Link>
                                            <Link className="col-6" to="/refund-policy">Refund/Cancellation Policy</Link>
                                            <Link className="col-6" to="/contact-us">Contact Us</Link>
                                            <Link className="col-6" to="/responsible-gaming">Responsible Gaming</Link>
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="footer-divider" />
                        <div className="px-3 py-4">
                            <div className="footer-text-bold">About Us</div><br />
                            <div className="footer-text">mrludoclub.com is a real-money gaming product owned and operated by
                                Gamicbond.pvt.ltd ("mrludoclub" or "We" or "Us" or "Our")..</div><br />
                            <div className="footer-text-bold">Our Business &amp; Products</div><br />
                            <div className="footer-text">We are an HTML5 game-publishing company and our mission is to make
                                accessing games fast and easy by removing the friction of app-installs.</div><br />
                            <div className="footer-text">mrludoclub is a skill-based real-money gaming platform accessible only
                                for our users in India. It is accessible on <Link to="/">https://www.mrludoclub.com.</Link> On mrludoclub, users can compete for real
                                cash in Tournaments and Battles. They can encash their winnings via popular options such
                                as Paytm Wallet, Amazon Pay, Bank Transfer, Mobile Recharges etc. </div><br />
                            <div className="footer-text-bold">Our Games</div><br />
                            <div className="footer-text">mrludoclub has a wide-variety of high-quality, premium HTML5 games. Our
                                games are especially compressed and optimised to work on low-end devices, uncommon
                                browsers, and patchy internet speeds. </div><br />
                            <div className="footer-text">
                                We have games across several popular categories: Arcade, Action, Adventure, Sports &amp;
                                Racing, Strategy, Puzzle &amp; Logic. We also have a strong portfolio of multiplayer games
                                such as Ludo, Chess, 8 Ball Pool, Carrom, Tic Tac Toe, Archery, Quiz, Chinese Checkers
                                and more! Some of our popular titles are: Escape Run, Bubble Wipeout, Tower Twist,
                                Cricket Gunda, Ludo With Friends. If you have any suggestions around new games that we
                                should add or if you are a game developer yourself and want to work with us, don't
                                hesitate to drop in a line at info@mrludoclub.com !
                            </div>
                        </div>
                    </div></section>

                {/* <Rightsidebar/> */}
            </div>


            <Outlet />
        </>

    );
}

export default Deshbord;