
import React, { useState, useEffect } from "react";
import loginimg from '../images/global-gameSheetSplash.png';
import axios from "axios";
// import history from './history';
import { useNavigate } from "react-router-dom";
import { Outlet, Link, Await, } from "react-router-dom";
import $ from "jquery";
import Swal from 'sweetalert2';
import loader from '../images/dancingloader.gif'
import withReactContent from 'sweetalert2-react-content';
import Rightsidebar from '../routes/Rightsidebar';
import io from 'socket.io-client';
// import WebSocket from './websocket.js';
// const jwt = require('jsonwebtoken');

// let user;

// Otp Api 
//const socket = io('https://ws.kheldost.com/');

const WebSocketComponent  = () => {
   const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Create a new WebSocket instance
    const ws = new WebSocket('wss://103.152.79.195:3006');

    // Set up event listeners
    ws.onopen = () => {
      console.log('WebSocket connection opened');
    };

    // ws.onmessage = (event) => {
    //   console.log('Received message:', event.data);
    //   // Handle the received message as needed
    // };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    // Save the WebSocket instance in the state
    setSocket(ws);

    // Clean up the WebSocket on component unmount
    return () => {
      ws.close();
    };
  }, []); // Only run this effect once when the component mounts

//   const sendMessage = (message) => {
//     // Check if the WebSocket connection is open before sending a message
//     if (socket && socket.readyState === WebSocket.OPEN) {
//       socket.send(message);
//     } else {
//       console.log('WebSocket connection not open.');
//     }
//   };



    return (
        <>
             <div>
      <h1>WebSocket Example</h1>
      
    </div>
        </>
    );
}
export default WebSocketComponent;
