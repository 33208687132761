
import React from "react";
import logo from '../images/mrludo.png'
import wp from '../images/wp.png'
import tg from '../images/telegram.png'

const Support = () => {
  return (
    <>
      <div className="leftContainer">
        <div className="container">
          {/* <div className="row justify-content-center ">
            <a href="#" className="btn btn-primary my-2 w-75" id="redeemNow">FAQ</a>
          </div> */}
          <div className="row">
            {/* <div className="col-md-6 text-center p-3 h2">
              
              <a href="" target="_blank">
              <img className="w-25" src={tg} alt="" />
                <p style={{ fontSize: '13px', marginTop: '5px' }}>Mrludoclub Telegram</p>
              </a>
            </div> */}
            <div className="col-md-6 text-center p-3 h2">
              <a href="//api.whatsapp.com/send?phone=9352853368&text=" className="linkedin-icon" title target="_blank">
                <img className="w-25" src={wp} alt="" />
                <p style={{ fontSize: '13px', marginTop: '5px' }}>Mrludoclub Whatsapp</p>
              </a>
            </div>

          </div>
          <div className="row mt-5">
            <div className="col-md-12 text-center mt-5">
              <p className="h5">Query for 24×7</p>
              <img className="w-25" src={logo} alt="" />
            </div>
          </div>
          <div className="divider-y" />
          <div className="rightContainer">
            <div className="rcBanner flex-center">
              <picture className="rcBanner-img-container">
                <img src="https://vplay.bet/frontend/images/vplaylogo1.png" alt="" />
              </picture>
              <div className="rcBanner-text">Vplay <span className="rcBanner-text-bold">Win Real Cash!</span></div>
              <div className="rcBanner-footer">For best experience, open&nbsp;<a href="#!" style={{ color: 'rgb(44, 44, 44)', fontWeight: 500, textDecoration: 'none' }}>vplay.bet</a>&nbsp;on&nbsp;<img src="https://vplay.bet/frontend/images/global-chrome.png" alt="" />&nbsp;chrome mobile</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Support;