import React, { useState, useEffect } from "react";
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// import { useNavigate } from "react-router-dom";
import axios from "axios";

const Refferamount = () => {
  // const [amount, setAmount] = useState("");
  const [gamebat, setGamebat] = useState(
    {
      amount: ''

    }
  )
  const { amount } = gamebat;
  const oninputchange = (e) => {
    setGamebat({ ...gamebat, [e.target.name]: e.target.value });
  }
  const [validationErrors, setValidationErrors] = useState({});


  const handleAddBankSubmit = async () => {
    const token = localStorage.getItem("token_store_vplay");
    try {
      // Reset validation errors
      setValidationErrors({});

      // Check if any of the required fields are empty
      const errors = {};


      if (!amount) {
        errors.amount = "Amount is required";
      }

      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      // Check if any field has null or undefined value
      if (
        amount === null

      ) {
        return;
      }
      $('#redeemNow').attr('disabled', 'true');

      var config = {
        method: 'post',
        url: 'https://api.mrludoclub.com/api/users/redeem-store',
        headers: {
          'x-access-token': token
        },
        data: gamebat
      };
      axios(config).then((response) => {
        $('#redeemNow').attr('disabled', 'false');
        console.warn(response);
        if (response.data.status == 1) {
          const MySwal = withReactContent(Swal)
          MySwal.fire({
            title: <strong>Amount is Successfully!</strong>,
            icon: 'success',
            timer: 1500
          })
            .then((result) => {
              window.location.reload();
            })
        } else {
          const MySwal = withReactContent(Swal)
          MySwal.fire({
            title: response.data.message,
            icon: 'error'
          })
        }
      }).catch(function (error) {
        console.log(error);
      });

    }

    catch (error) {
      console.error("Error adding bank:", error);
      // Handle error (e.g., display error message)
    }
  };
  useEffect(
    () => {
      multiple_auth_chk();
    }, []
  );
  const multiple_auth_chk = async () => {


    var data = '';
    const ipaddress = localStorage.getItem("ipaddress");
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: 'post',
      url: 'https://api.mrludoclub.com/api/users/multiple-auth-chk',
      headers: {
        'x-access-token': token
      },
      data: { ipaddress: ipaddress }
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        // console.warn(objectRes.data[0]['total']);
        var totlaipp = objectRes.data[0]['ip'];
        // alert(totlaipp);
        if (totlaipp != ipaddress) {
          localStorage.setItem('token_store_vplay', ' ');
          localStorage.setItem('userid', ' ');
          window.location.href = '/login';
        }
        console.warn(totlaipp);
        // setUsers(objectRes);
      })
      .catch(function (error) {
        console.log(error);
      });
    if (!token) {
      window.location.href = '/login';
      // exist();
    }
  }

  // const handleAmountChange = (e) => {
  //   setAmount(e.target.value);
  // };
  return (
    <>

      <div className="leftContainer">
        <div className="width_amount">
          <div className="main-area" style={{ paddingTop: '60px' }}>
            <h5 className="mt-4">Redeem your Refer balance</h5>
            <div id="sign_up_success" />
            <p>TDS (5%) will be deducted after annual referral earning of ₹0.</p>
            <form method="post">
              <label>Enter Amount (Min: 100, Max: 10000)</label>
              <input type="number" name="amount" id="amount" className="form-control border-bottom" placeholder="₹" style={{ border: 'none' }} value={amount} onChange={oninputchange} />

              {validationErrors.amount && <p>{validationErrors.amount}</p>}
              <label className="mt-2">Money will be added to Mrludoclub cash.</label>
              <br />
              <span className="amount-error mb-2" id="amount_error" />
              <br />
              <br />
              <div>
                <button type="button" className="btn btn-primary w-100 py-3" id="redeemNow" onClick={handleAddBankSubmit}>REDEEM</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Refferamount;